import React from "react";
import { Box, Grid, Typography, ListItem, List, Button } from "@mui/material";
import "./Opendemat.scss";
// import broker1 from "../../images/aliceblue.png";
// import broker2 from "../../images/zebull.png";
// import broker3 from "../../images/paisa.png";
// import broker4 from "../../images/anglebroking.png";
// import broker5 from "../../images/finvasia.png";
// import broker6 from "../../images/icici.png";
// import broker7 from "../../images/kotak.png";
// import broker8 from "../../images/motialoswal.png";
// import broker9 from "../../images/swastika.png";
// import broker10 from "../../images/zerodha.png";
// import broker11 from "../../images/fyers.png";
// import broker12 from "../../images/rmoney.png";
// import broker13 from "../../images/sharekhan.png";
// import broker14 from "../../images/dhan.png";
// import broker15 from "../../images/goodwill.png";
// import broker16 from "../../images/espresso.png";
import { useDispatch, useSelector } from "react-redux";
import { openDemate } from "../../redux/actions/brokerAction";
import { useEffect } from "react";
import { getProfile } from "../../redux/actions/authActions";
import detailData from "./demateDetails.json";

export default function Opendemat() {
  const dispatch = useDispatch();
  const Link = (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0691 1.28638H17.351V5.54129"
        stroke="currentColor"
        strokeOpacity="0.8"
        strokeWidth="1.62825"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4946 10.648V15.7548C16.4946 16.6949 15.7278 17.457 14.7818 17.457H2.79258C1.84665 17.457 1.07983 16.6949 1.07983 15.7548V3.83897C1.07983 2.89884 1.84665 2.13672 2.79258 2.13672H7.93083"
        stroke="currentColor"
        strokeOpacity="0.8"
        strokeWidth="1.62825"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.6436 8.94601L16.9228 1.71143"
        stroke="currentColor"
        strokeOpacity="0.8"
        strokeWidth="1.62825"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const user = useSelector((state) => state.Auth.authProfile);

  useEffect(() => {
    const obj = {
      refrecode: user?.[0]?.refrecode,
    };
    if (user?.[0]?.refrecode) {
      dispatch(openDemate(obj));
    }
  }, [user]);

  const data = useSelector((state) => {
    return state?.Broker?.openDemate?.[0];
  });

  useEffect(() => {
    dispatch(getProfile());
  }, []);

  const brokerList = [
    {
      brokerImg:
        "https://emailwala.s3.ap-south-1.amazonaws.com/Alice+Blue+Broker.png",
    },
    {
      brokerImg:
        "https://emailwala.s3.ap-south-1.amazonaws.com/Zebu+Broker.png",
    },
    {
      brokerImg:
        "https://emailwala.s3.ap-south-1.amazonaws.com/5Paisa+Broker.png",
    },
    {
      brokerImg:
        "https://emailwala.s3.ap-south-1.amazonaws.com/Angel+One+Broker.png",
    },
    {
      brokerImg:
        "https://emailwala.s3.ap-south-1.amazonaws.com/Finvasia+Broker.png",
    },
    {
      brokerImg:
        "https://emailwala.s3.ap-south-1.amazonaws.com/ICICI+Direct+Broker.png",
    },
    {
      brokerImg:
        "https://emailwala.s3.ap-south-1.amazonaws.com/Kotak+Broker.png",
    },
    {
      brokerImg:
        "https://emailwala.s3.ap-south-1.amazonaws.com/Motilal+Broker.png",
    },
    {
      brokerImg:
        "https://emailwala.s3.ap-south-1.amazonaws.com/Swastiks+Broker.png",
    },
    {
      brokerImg:
        "https://emailwala.s3.ap-south-1.amazonaws.com/Zerodha+Broker.png",
    },
    {
      brokerImg:
        "https://emailwala.s3.ap-south-1.amazonaws.com/Fyers+Broker.png",
    },
    {
      brokerImg:
        "https://emailwala.s3.ap-south-1.amazonaws.com/RMoney+Broker.png",
    },
    {
      brokerImg:
        "https://emailwala.s3.ap-south-1.amazonaws.com/Sherkhan+Broker.png",
    },
    {
      brokerImg:
        "https://emailwala.s3.ap-south-1.amazonaws.com/Dhan+Broker.png",
    },
    {
      brokerImg:
        "https://emailwala.s3.ap-south-1.amazonaws.com/GoodWill+Broker.png",
    },
    {
      brokerImg:
        "https://emailwala.s3.ap-south-1.amazonaws.com/Expresso+Broker.png",
    },
  ];

  const Card = (props) => {
    const { image, link, check, customKey, details } = props;

    return (
      check && (
        <Grid className="dematCard-item" key={customKey}>
          <Box className="dematCard">
            <Box className="dematCard-img">
              <img src={image} />
            </Box>
            <Box className="dematCard-content">
              <List>
                {details &&
                  details?.length > 0 &&
                  details?.map((e) => {
                    return (
                      <>
                        <ListItem>{e}</ListItem>
                      </>
                    );
                  })}
              </List>

              <Button
                onClick={() => window.open(link, "_blank")}
                className="dematCard-btn"
              >
                {Link}Click here to Proceed
              </Button>
            </Box>
          </Box>
        </Grid>
      )
    );
  };

  return (
    <Box className="dematBox">
      <Grid container spacing={2} justifyContent={"center"}>
        <Grid item xs={7} textAlign={"center"}>
          <Typography className="title" component={"h2"}>
            Open Demat
          </Typography>
          <Typography className="tagLine" component={"p"}>
            Choose from our top associate brokers and create your own trading
            account.
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        justifyContent={"center"}
        sx={{
          maxWidth: { xs: "1550px" },
          margin: "auto",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: { xs: "flex" },
            flexWrap: { xs: "wrap" },
            justifyContent: "left",
          }}
        >
          <Card
            details={detailData?.alice}
            customKey={1}
            image={"https://logo12.blr1.digitaloceanspaces.com/1.png"}
            link={data?.alice_link}
            check={data?.is_alice_demat}
          ></Card>
          <Card
            details={detailData?.zebull}
            customKey={2}
            image={"https://logo12.blr1.digitaloceanspaces.com/4.png"}
            link={data?.zebull_link}
            check={data?.is_zebull_demat}
          ></Card>
          <Card
            details={detailData?.paisa}
            customKey={3}
            image={
              "https://tradearthimage.s3.ap-south-1.amazonaws.com/FivePaisa.png"
            }
            link={data?.paisa_link}
            check={data?.is_paisa_demat}
          ></Card>
          <Card
            details={detailData?.angel}
            customKey={4}
            image={"https://logo12.blr1.digitaloceanspaces.com/2.png"}
            link={data?.angelone_link}
            check={data?.is_angelone_demat}
          ></Card>
          <Card
            details={detailData?.finvasia}
            customKey={5}
            image={"https://logo12.blr1.digitaloceanspaces.com/7.png"}
            link={data?.finvensia_link}
            check={data?.is_finvensia_demat}
          ></Card>
          <Card
            details={detailData.icici}
            customKey={6}
            image={
              "https://tradearthimage.s3.ap-south-1.amazonaws.com/IciciDirect.png"
            }
            link={data?.icici_link}
            check={data?.is_icici_demat}
          ></Card>
          <Card
            details={detailData.kotak}
            customKey={7}
            image={
              "https://tradearthimage.s3.ap-south-1.amazonaws.com/KotakNeo.png"
            }
            link={data?.kotak_link}
            check={data?.is_kotak_demat}
          ></Card>
          <Card
            details={detailData.motilal}
            customKey={8}
            image={"https://logo12.blr1.digitaloceanspaces.com/10.png"}
            link={data?.motilal_link}
            check={data?.is_motilal_demat}
          ></Card>
          <Card
            details={detailData.swastika}
            customKey={9}
            image={"https://logo12.blr1.digitaloceanspaces.com/3.png"}
            link={data?.swastika_link}
            check={data?.is_swastika_demat}
          ></Card>
          <Card
            details={detailData.zerodha}
            customKey={10}
            image={
              "https://tradearthimage.s3.ap-south-1.amazonaws.com/Zerodha.png"
            }
            link={data?.zerodha_link}
            check={data?.is_zerodha_demat}
          ></Card>
          <Card
            details={detailData.fyers}
            customKey={11}
            image={
              "https://tradearthimage.s3.ap-south-1.amazonaws.com/Fyers.png"
            }
            link={data?.fyres_link}
            check={data?.is_fyres_demat}
          ></Card>
          <Card
            details={detailData.rmoney}
            customKey={12}
            image={
              "https://emailwala.s3.ap-south-1.amazonaws.com/RMoney+Broker.png"
            }
            link={data?.rmoney_link}
            check={data?.is_rmoney_demat}
          ></Card>
          <Card
            details={detailData?.sharekhan}
            customKey={13}
            image={
              "https://tradearthimage.s3.ap-south-1.amazonaws.com/Sharekhan.png"
            }
            link={data?.sharekhan_link}
            check={data?.is_sharekhan_demat}
          ></Card>
          <Card
            details={detailData.dhan}
            customKey={14}
            image={"https://logo12.blr1.digitaloceanspaces.com/6.png"}
            link={data?.dhan_link}
            check={data?.is_dhan_demat}
          ></Card>
          <Card
            details={detailData.goodwill}
            customKey={15}
            image={"https://logo12.blr1.digitaloceanspaces.com/16.png"}
            link={data?.goodwill_link}
            check={data?.is_goodwill_demat}
          ></Card>
          <Card
            details={detailData.expresso}
            customKey={16}
            image={
              "https://tradearthimage.s3.ap-south-1.amazonaws.com/Espresso.png"
            }
            link={data?.expresso_link}
            check={data?.is_expresso_demat}
          ></Card>
          <Card
            details={detailData.flattrade}
            customKey={16}
            image={
              "https://tradearthimage.s3.ap-south-1.amazonaws.com/Flatrade.png"
            }
            link={data?.flattrade_link}
            check={data?.is_flattrade_demat}
          ></Card>
          <Card
            details={detailData.Nuvama}
            customKey={16}
            image={
              "https://tradearthimage.s3.ap-south-1.amazonaws.com/Nuvama.png"
            }
            link={data?.nuvama_link}
            check={data?.is_nuvama_demat}
          ></Card>
          <Card
            details={detailData.profitmart}
            customKey={16}
            image={
              "https://emailwala.s3.ap-south-1.amazonaws.com/ProfitMart+Broker.png"
            }
            link={data?.profitmart_link}
            check={data?.is_profitmart_demat}
          ></Card>
          <Card
            details={detailData.upstox}
            customKey={16}
            image={"https://logo12.blr1.digitaloceanspaces.com/19.png"}
            link={data?.upstox_link}
            check={data?.is_upstox_demat}
          ></Card>
          {/* {brokerList.map((item, index) => (
            <Grid className="dematCard-item" key={index}>
              <Box className="dematCard">
                <Box className="dematCard-img">
                  <img src={item.brokerImg} />
                </Box>
                <Box className="dematCard-content">
                  <List>
                    <ListItem>{15 * index}/- Rs per Order</ListItem>
                    <ListItem>All equity delivery15/- Rs </ListItem>
                    <ListItem>Account openingcharges free . </ListItem>
                  </List>
                  <Button className="dematCard-btn">
                    {Link}Click here to Proceed
                  </Button>
                </Box>
              </Box>
            </Grid>
          ))} */}
        </Grid>
      </Grid>
    </Box>
  );
}
