import React from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import Autologin from "./Autologin/Autologin";
import Brokerid from "./Brokerid/Brokerid";
import Capitalamount from "./Capitalamount/Capitalamount";
import Checkbalance from "./Checkbalance/Checkbalance";
import Ctrade from "./Copytrade/Copytrade";
import Cronview from "./Cronview/Cronview";
import Ctradestrategy from "./Ctradesrategy/Ctradesrategy";
import Dblivetrade from "./Dblivetrade/DB_livetrade";
import Dbpapertrade from "./Dbpapertrade/DB_papertrade";
import Orderhistory from "./Orderhistory/Orderhistory";
import Plview from "./Plview/Plview";
import Servicescharge from "./Servicescharge/Servicescharge";
import Strategyalert from "./Strategyalert/Strategyalert";
import Strategyperfomance from "./Strategyperfomance/Strategyperfomance";
import Symboltoken from "./Symboltoken/Symboltoken";
import Tradelog from "./Tradelog/Tradelog";
import Tradeview from "./Tradeview/Tradeview";
import Userpl from "./Userplrepoert/Userplrepoert";
import Strategycharges from "./Strategycharges/Strategycharges";
import Strategybuilder from "../../Strategybuilder/Strategybuilder";
import Userstrategy from "../Masterupdate/Userstrategy/Userstrategy";
import Copytrade from "./Copytrade2/Copytrade";
import SignalScanner from "./SignalScanner/SignalScanner";
import Creatastrategy from "./Creatastrategy/Creatastrategy";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Smstextalert from "../Smstextalert/Smstextalert";
import BrokerLoginRemove from "./BrokerLoginRemove/BrokerLoginRemove";
import SocketService from "./SocketService/SocketService";
import BrokerPermission from "./BrokerPermission/BrokerPermission";
import GenerateKey from "./GenerateKey/GenerateKey";

export default function Others() {
  // admin tab
  const [value, setValue] = React.useState("admintab6");
  const handleChange = (e, newValue = "string") => {
    setValue(newValue);
  };

  const userData = useSelector((state) => state?.Auth?.authProfile?.[0]);

  return (
    <Box className="tabs">
      <TabContext value={value}>
        <TabList className="main-tab" onChange={handleChange}>
          {/* <Tab label="Auto Login" value={"admintab1"} /> */}
          {/* <Tab label="Broker ID" value={"admintab2"} /> */}
          {/* <Tab label="Capital Amount" value={"admintab3"} /> */}
          {/* <Tab label="Fund View" value={"admintab4"} /> */}
          {/* <Tab label="Admin Copytrade" value={"admintab5"} /> */}
          {userData?.is_staff && <Tab label="Crone View" value={"admintab6"} />}
          {/* <Tab label="C - Trade Strategy" value={"admintab7"} /> */}
          {/* <Tab label="DB Live Trade" value={"admintab8"} /> */}
          {/* <Tab label="DB Paper Trade" value={"admintab9"} /> */}
          {/* <Tab label="Order History" value={"admintab10"} /> */}
          {/* <Tab label="P&L View" value={"admintab11"} /> */}
          {/* <Tab label="Services Charge" value={"admintab12"} /> */}
          {/* <Tab label="Strategy Alert" value={"admintab13"} /> */}
          {/* <Tab label="Strategy Perfomance" value={"admintab14"} /> */}
          {userData?.is_staff && (
            <Tab label="Symbol Token" value={"admintab15"} />
          )}
          {/* <Tab label="Trade Log" value={"admintab16"} /> */}
          {/* <Tab label="Trade View" value={"admintab17"} /> */}
          {/* <Tab label="User P&L Report" value={"admintab18"} /> */}
          {userData?.is_staff && (
            <Tab label="Service Charges" value={"admintab19"} />
          )}
          {/* <Tab label="Strategy Builder" value={"admintab20"} /> */}
          {/* {userData?.is_staff && (
            <Tab label="User Strategy" value={"admintab21"} />
          )} */}
          {userData?.is_staff && (
            <Tab label="Create Strategy" value={"admintab22"} />
          )}
          {userData?.is_staff && (
            <Tab label="Sms Text Alert" value={"admintab23"} />
          )}
          {userData?.is_staff && (
            <Tab label="Broker Login Remove" value={"admintab24"} />
          )}
          {userData?.is_staff && (
            <Tab label="Socket Permission" value={"admintab26"} />
          )}
          <Tab label="Trade Signal Scanner" value={"admintab25"} />
          <Tab label="Generate Key" value={"admintab28"} />
          {(userData?.is_staff ||
            userData?.is_normalbroker ||
            userData?.is_powerbroker) && (
            <Tab label="Broker Permission" value={"admintab27"} />
          )}
        </TabList>

        <Box className="tabBox" border={"none !important"}>
          {/* <TabPanel value={"admintab1"} sx={{ padding: 0 }}><Autologin /></TabPanel> */}
          {/* <TabPanel value={"admintab2"} sx={{ padding: 0 }}><Brokerid /></TabPanel> */}
          {/* <TabPanel value={"admintab3"} sx={{ padding: 0 }}><Capitalamount /></TabPanel> */}
          {/* <TabPanel value={"admintab4"} sx={{ padding: 0 }}><Checkbalance /></TabPanel> */}
          {/* <TabPanel value={"admintab5"} sx={{ padding: 0 }}>
            <Copytrade />
          </TabPanel> */}

          <TabPanel value={"admintab6"} sx={{ padding: 0 }}>
            <Cronview />
          </TabPanel>
          <TabPanel value={"admintab7"} sx={{ padding: 0 }}>
            <Ctradestrategy />
          </TabPanel>
          {/* <TabPanel value={"admintab8"} sx={{ padding: 0 }}><Dblivetrade /></TabPanel> */}
          {/* <TabPanel value={"admintab9"} sx={{ padding: 0 }}><Dbpapertrade /></TabPanel> */}
          {/* <TabPanel value={"admintab10"} sx={{ padding: 0 }}><Orderhistory /></TabPanel> */}
          {/* <TabPanel value={"admintab11"} sx={{ padding: 0 }}><Plview /></TabPanel> */}
          {/* <TabPanel value={"admintab12"} sx={{ padding: 0 }}><Servicescharge /></TabPanel> */}
          {/* <TabPanel value={"admintab13"} sx={{ padding: 0 }}><Strategyalert /></TabPanel> */}
          {/* <TabPanel value={"admintab14"} sx={{ padding: 0 }}><Strategyperfomance /></TabPanel> */}
          <TabPanel value={"admintab15"} sx={{ padding: 0 }}>
            <Symboltoken />
          </TabPanel>
          <TabPanel value={"admintab16"} sx={{ padding: 0 }}>
            <Tradelog />
          </TabPanel>
          {/* <TabPanel value={"admintab17"} sx={{ padding: 0 }}><Tradeview /></TabPanel> */}
          {/* <TabPanel value={"admintab18"} sx={{ padding: 0 }}><Userpl /></TabPanel> */}
          <TabPanel value={"admintab19"} sx={{ padding: 0 }}>
            <Strategycharges />
          </TabPanel>
          {/* <TabPanel value={"admintab20"} sx={{ padding: 0 }}><Strategybuilder /></TabPanel> */}
          <TabPanel value={"admintab21"} sx={{ padding: 0 }}>
            <Userstrategy />
          </TabPanel>
          <TabPanel value={"admintab22"} sx={{ padding: 0 }}>
            <Creatastrategy />
          </TabPanel>
          <TabPanel value={"admintab23"} sx={{ padding: 0 }}>
            <Smstextalert />
          </TabPanel>
          <TabPanel value={"admintab24"} sx={{ padding: 0 }}>
            <BrokerLoginRemove />
          </TabPanel>
          <TabPanel value={"admintab25"} sx={{ padding: 0 }}>
            <SignalScanner />
          </TabPanel>
          <TabPanel value={"admintab28"} sx={{ padding: 0 }}>
            <GenerateKey />
          </TabPanel>
          <TabPanel value={"admintab26"} sx={{ padding: 0 }}>
            <SocketService />
          </TabPanel>
          <TabPanel value={"admintab27"} sx={{ padding: 0 }}>
            <BrokerPermission />
          </TabPanel>
        </Box>
      </TabContext>
    </Box>
  );
}
