import {
  Button,
  FormControlLabel,
  Radio,
  Box,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  editPowerBroker,
  getPowerBroker,
  openDemate,
} from "../../../redux/actions/brokerAction";
import { toast } from "react-toastify";

function DistributedBroker() {
  const edit = <Typography sx={{ fontSize: "1.4rem" }}>Edit</Typography>;
  const [isEditable, setIsEditable] = useState("");
  const [brokerAllow, setAllowBroker] = useState("");
  const [link, setLink] = useState("");
  const [brokerDemate, setBrokerDemate] = useState("");
  const dispatch = useDispatch();
  const user = useSelector((state) => state.Auth.authProfile);
  const demateData = useSelector((state) => {
    return state?.Broker?.openDemate?.[0];
  });

  const showbrokerSave = (items, index) => {
    setLink(items?.webLink);
    setAllowBroker(false);
    setBrokerDemate(items?.demate);
    if (isEditable !== index) {
      setIsEditable(index);
    } else {
      setIsEditable("");
    }
  };

  const handleDemateSave = (items, index) => {
    const obj = {
      broker: items?.name?.toLowerCase().replace(/ +/g, ""),
      brokerallow: brokerAllow ? "True" : "False",
      //   brokerlink: link ? link : "",
      brokerdemat: brokerDemate ? "True" : "False",
    };

    dispatch(editPowerBroker(obj)).then((res) => {
      if (res?.status === 200) {
        const obj = {
          refrecode: user?.[0]?.username,
        };
        dispatch(getPowerBroker());
        if (user?.[0]?.username) {
          dispatch(openDemate(obj));
          setIsEditable("");
        }
      }
    });
  };

  const powerBroker = useSelector((state) => state.Broker?.powerBrokers);

  useEffect(() => {
    const obj = {
      refrecode: user?.[0]?.username,
    };
    if (user?.[0]?.username) {
      // demate data for default
      dispatch(openDemate(obj));
    }
  }, [user]);

  useEffect(() => {
    dispatch(getPowerBroker());
  }, []);

  const brokers = [
    {
      name: "Alice Blue",
      brokerImg: "https://logo12.blr1.digitaloceanspaces.com/1.png",
      demate: demateData?.is_alice_demat,
      brokerAllow: powerBroker?.[0]?.is_alice === true ? true : false,
    },
    {
      name: "Zebull",
      brokerImg: "https://logo12.blr1.digitaloceanspaces.com/4.png",
      demate: demateData?.is_zebull_demat,
      brokerAllow: powerBroker?.[0]?.is_zebull === true ? true : false,
    },
    {
      name: "5 Paisa",
      brokerImg: "https://logo12.blr1.digitaloceanspaces.com/8.png",
      demate: demateData?.is_paisa_demat,
      brokerAllow: powerBroker?.[0]?.is_5paisa === true ? true : false,
    },
    {
      name: "Angel One",
      brokerImg: "https://logo12.blr1.digitaloceanspaces.com/2.png",
      demate: demateData?.is_angelone_demat,
      brokerAllow: powerBroker?.[0]?.is_angelone === true ? true : false,
    },
    {
      name: "Finvasia",
      brokerImg: "https://logo12.blr1.digitaloceanspaces.com/7.png",
      demate: demateData?.is_finvensia_demat,
      brokerAllow: powerBroker?.[0]?.is_finvesia === true ? true : false,
    },
    {
      name: "ICICI",
      brokerImg: "https://logo12.blr1.digitaloceanspaces.com/12.png",
      demate: demateData?.is_icici_demat,
      brokerAllow: powerBroker?.[0]?.is_icici === true ? true : false,
    },
    {
      name: "Kotak",
      brokerImg: "https://logo12.blr1.digitaloceanspaces.com/11.png",
      demate: demateData?.is_kotak_demat,
      brokerAllow: powerBroker?.[0]?.is_kotak === true ? true : false,
    },
    {
      name: "Motilal",
      brokerImg: "https://logo12.blr1.digitaloceanspaces.com/10.png",
      demate: demateData?.is_motilal_demat,
      brokerAllow: powerBroker?.[0]?.is_motilal === true ? true : false,
    },
    {
      name: "Swastika",
      brokerImg: "https://logo12.blr1.digitaloceanspaces.com/3.png",
      demate: demateData?.is_swastika_demat,
      brokerAllow: powerBroker?.[0]?.is_swastika === true ? true : false,
    },
    {
      name: "Zerodha",
      brokerImg: "https://logo12.blr1.digitaloceanspaces.com/9.png",
      demate: demateData?.is_zerodha_demat,
      brokerAllow: powerBroker?.[0]?.is_zerodha === true ? true : false,
    },
    {
      name: "Fyres",
      brokerImg: "https://logo12.blr1.digitaloceanspaces.com/5.png",
      demate: demateData?.is_fyres_demat,
      brokerAllow: powerBroker?.[0]?.is_fyres === true ? true : false,
    },
    {
      name: "Dhan",
      brokerImg: "https://logo12.blr1.digitaloceanspaces.com/6.png",
      demate: demateData?.is_dhan_demat,
      brokerAllow: powerBroker?.[0]?.is_dhan === true ? true : false,
    },
    {
      name: "Goodwill",
      brokerImg: "https://logo12.blr1.digitaloceanspaces.com/16.png",
      demate: demateData?.is_goodwill_demat,
      brokerAllow: powerBroker?.[0]?.is_goodwill === true ? true : false,
    },
    {
      name: "Sharekhan",
      brokerImg: "https://logo12.blr1.digitaloceanspaces.com/17.png",
      demate: demateData?.is_sharekhan_demat,
      brokerAllow: powerBroker?.[0]?.is_sharekhan === true ? true : false,
    },
    {
      name: "Expresso",
      brokerImg: "https://logo12.blr1.digitaloceanspaces.com/13.png",
      demate: demateData?.is_expresso_demat,
      brokerAllow: powerBroker?.[0]?.is_expresso === true ? true : false,
    },
    {
      name: "RMoney",
      brokerImg: "https://logo12.blr1.digitaloceanspaces.com/14.png",
      demate: demateData?.is_rmoney_demat,
      brokerAllow: powerBroker?.[0]?.is_rmoney === true ? true : false,
    },
    {
      name: "Nuvama",
      brokerImg: "https://logo12.blr1.digitaloceanspaces.com/15.png",
      demate: demateData?.is_nuvama_demat,
      brokerAllow: powerBroker?.[0]?.is_nuvama === true ? true : false,
    },
    {
      name: "Flattrade",
      brokerImg: "https://logo12.blr1.digitaloceanspaces.com/18.png",
      demate: demateData?.is_flattrade_demat,
      brokerAllow: powerBroker?.[0]?.is_flattrade === true ? true : false,
    },
    {
      name: "Profitmart",
      brokerImg: "https://logo12.blr1.digitaloceanspaces.com/20.png",
      demate: demateData?.is_profitmart_demat,
      brokerAllow: powerBroker?.[0]?.is_profitmart === true ? true : false,
    },
    {
      name: "Upstox",
      brokerImg: "https://logo12.blr1.digitaloceanspaces.com/19.png",
      demate: demateData?.is_upstox_demat,
      brokerAllow: powerBroker?.[0]?.is_upstox === true ? true : false,
    },
    // {
    //   name: "Mandot",
    //   brokerImg:
    //     "https://emailwala.s3.ap-south-1.amazonaws.com/Mandot+Broker.png",
    //   demate: demateData?.is_mandot_demat,
    //   brokerAllow: powerBroker?.[0]?.is_mandot === true ? true : false,
    // },
  ];

  return (
    <div>
      <TableContainer sx={{ MarginTop: 16 }}>
        <Table sx={{ minWidth: "100%" }} className="table tableData allowTable">
          <TableHead>
            <TableRow>
              <TableCell>Broker Name</TableCell>
              <TableCell>Broker Allow</TableCell>
              {/* <TableCell>Web Link</TableCell> */}
              <TableCell>Demat</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {brokers.map((items, index) => (
              <>
                <TableRow key={index}>
                  <TableCell>
                    <Box className="brokerName">
                      {items.name} <img src={items.brokerImg} />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box className="inputFields">
                      <RadioGroup
                        row
                        defaultValue={items?.brokerAllow ? "yes" : "no"}
                        onChange={(e) => {
                          setAllowBroker(
                            e.target.value === "yes" ? true : false
                          );
                        }}
                      >
                        <FormControlLabel
                          disabled={!(isEditable === index)}
                          value="yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          disabled={!(isEditable === index)}
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </Box>
                  </TableCell>
                  {/* <TableCell>
                    {!(isEditable === index) ? (
                      <Typography
                        href={items?.webLink}
                        className="dematLink"
                        component={"a"}
                      >
                        {items?.webLink === "NA" ? "-" : items?.webLink}
                      </Typography>
                    ) : (
                      <Box className="inputFields space fullWidth space-y">
                        <TextField
                          defaultValue={items.webLink}
                          onChange={(e) => {
                            setLink(e?.target?.value);
                          }}
                        />
                      </Box>
                    )}
                  </TableCell> */}
                  <TableCell>
                    <Box className="inputFields">
                      <RadioGroup
                        row
                        defaultValue={items?.demate ? "yes" : "no"}
                        onChange={(e) => {
                          setBrokerDemate(
                            e.target.value === "yes" ? true : false
                          );
                        }}
                      >
                        <FormControlLabel
                          onClick={() => {
                            (link === "NA" || link === "") &&
                              isEditable === index &&
                              toast.error("please enter web link");
                          }}
                          disabled={
                            !(
                              isEditable === index &&
                              !(link === "NA" || link === "")
                            )
                          }
                          value="yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          disabled={!(isEditable === index)}
                          value="no"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "left",
                        width: "100%",
                      }}
                    >
                      <Tooltip title={edit} arrow placement="top">
                        <Button
                          disabled={isEditable === index ? true : false}
                          className="fetchReport-btn solidButton"
                          onClick={() => showbrokerSave(items, index)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 512 512"
                          >
                            {" "}
                            <path
                              d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </Button>
                      </Tooltip>
                      <Button
                        className="formSolid-btn"
                        sx={{
                          marginLeft: 1,
                          display: isEditable === index ? "block" : "none",
                        }}
                        onClick={() => {
                          handleDemateSave(items, index);
                        }}
                      >
                        Save
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default DistributedBroker;
