import React, { useState, useEffect } from "react";
import { Box, Grid, Tooltip, Button, Typography } from "@mui/material";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import Table from "../Table/Table";
import Dropdown from "../Dropdown/Dropdown";
import "./Plreport.scss";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import {
  getSegment,
  paperPlReportPdf,
  plReportPdf,
} from "../../redux/actions/positionAction";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  liveProfitDetails,
  paperProfitDetails,
} from "../../redux/actions/plreportActions";
import { getStratergy } from "../../redux/actions/stratergyAction";

const col = [
  "Symbol",
  "Owner",
  "Exchange",
  "Product",
  "Side",
  "Buy Price",
  "Sell Price",
  "Buy Quantity",
  "Sell Quantity",
  "Profit",
  "Stratergy",
  "Date",
];

const drpValue2 = ["Select Trade Type", "Paper", "Live"];
const drpValue3 = ["Select Trading Type", "Intraday", "Positional "];

export default function Plreport(props) {
  const [strategy, setStrategy] = useState("select");
  const dispatch = useDispatch();
  const small = "small";
  const download = (
    <Typography component={"p"} sx={{ fontSize: "1.2rem" }}>
      Download
    </Typography>
  );
  const refresh = (
    <Typography component={"p"} sx={{ fontSize: "1.2rem" }}>
      Refresh
    </Typography>
  );

  useEffect(() => {
    dispatch(getSegment());
  }, []);

  // const handleSearch = (event, value) => {
  //   setStrategy(event?.target?.value);
  // };

  const segmentList = useSelector((state) => state?.Position?.segmentList);

  const [todate, setTodate] = useState(null);
  const [fromdate, setFromdate] = useState(null);
  const [segment, setSegment] = useState("select");
  const [tradeType, setTradeType] = useState("live");

  //select to date
  const handleTodate = (date) => {
    setTodate(dayjs(date).format("YYYY-MM-DD"));
  };

  //select fromdate
  const handleFromdate = (date) => {
    setFromdate(dayjs(date).format("YYYY-MM-DD"));
  };

  //select segment
  const handleSegment = (event) => {
    setSegment(event.target.value);
  };

  //select tradeType
  const handleTradeType = (event) => {
    setTradeType(event.target.value);
  };

  const handleDownload = () => {
    const obj = {
      segment: segment === "select" ? "select" : segment.toUpperCase(),
      stratergy: strategy === "select" ? "select" : strategy.toUpperCase(),
      fromdate: fromdate,
      todate: todate,
    };
    if (tradeType === "live") {
      dispatch(plReportPdf(obj));
    } else {
      dispatch(paperPlReportPdf(obj));
    }
  };

  useEffect(() => {
    const obj = {
      stratergy: strategy === "select" ? "select" : strategy.toUpperCase(),
      segment: segment === "select" ? "select" : segment.toUpperCase(),
      startdate: fromdate ? fromdate : moment(new Date()).format("YYYY-MM-DD"),
      enddate: todate ? todate : moment(new Date()).format("YYYY-MM-DD"),
    };
    if (tradeType === "live") {
      dispatch(liveProfitDetails(obj));
    } else {
      dispatch(paperProfitDetails(obj));
    }
  }, [fromdate, todate, tradeType, segment, strategy]);

  const liveProfitdetails = useSelector(
    (state) => state?.Plreport?.liveProfitdetails
  );

  const data = liveProfitdetails?.map((e) => ({
    Symbol: e?.symbol,
    Owner: e?.owner,
    Exchange: e?.exchange,
    Product: e?.product,
    Side: (
      <span
        className={
          e?.side?.toUpperCase() === "BUY" || e?.side?.toUpperCase() === "B"
            ? "up"
            : "down"
        }
      >
        {e.side}
      </span>
    ),
    Buy_Price: e?.buyprice,
    Sell_Price: e?.sellprice,
    Buy_Quantity: e?.buyquantity,
    Sell_Quantity: e?.sellquantity,
    Profit: (
      <span className={Number(e?.profit) >= 0 ? "up" : "down"}>{e.profit}</span>
    ),
    Stratergy: e?.stratergy,
    Date: dayjs(e?.date).format("DD-MM-YYYY HH:mm"),
  }));

  const filteredData = data.sort(
    (a, b) => new Date(b?.Date) - new Date(a?.Date)
  );

  useEffect(() => {
    dispatch(getStratergy());
  }, []);

  const stratergy = useSelector((state) => state?.Stratergy?.stratergy);
  const stratergyList = stratergy?.map((e) => e?.name);

  const handleStrategy = (event) => {
    setStrategy(event.target.value);
  };

  const handleRefresh = () => {
    setStrategy("select");
    setSegment("select");
    setTodate(moment(new Date()).format("YYYY-MM-DD"));
    setFromdate(moment(new Date()).format("YYYY-MM-DD"));
  };

  return (
    <Box className="tabelBox" sx={{ padding: { xs: "1rem 0", md: "0" } }}>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item xs={12}>
          <Box className="selectiondiv-box m-rb">
            <Box className="selectionDiv">
              <Dropdown
                val={["select"]?.concat(stratergyList)}
                handleSelect={handleStrategy}
                value={strategy}
                title={"Select Strategy"}
              />{" "}
              {/* <Autocomplete
                onInputChange={handleSearch}
                placeholder="Select Broker"
                value={strategy ? strategy?.toUpperCase() : ""}
                inputValue={strategy ? strategy?.toUpperCase() : ""}
                className="dropdown"
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Broker" />
                )}
                options={stratergyList}
                onChange={handleSearch}
                getOptionLabel={(option) => option}
                sx={{
                  "& > .MuiFormControl-root > .MuiInputBase-root": {
                    padding: "0.5rem !important",
                  },
                }}
              /> */}
              <Typography component={"label"} className="label">
                From Date:{" "}
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="datePicker"
                  value={fromdate && dayjs(fromdate)}
                  onChange={handleFromdate}
                />
              </LocalizationProvider>
            </Box>
            <Box className="selectionDiv">
              <Typography component={"label"} className="label">
                To Date:{" "}
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={todate && dayjs(todate)}
                  className="datePicker"
                  onChange={handleTodate}
                />
              </LocalizationProvider>
            </Box>

            <Dropdown
              val={["select"]?.concat(segmentList)}
              handleSelect={(event) => handleSegment(event)}
              value={segment}
              title={"Select Segment"}
            />
            <Dropdown
              val={drpValue2}
              handleSelect={(event) => handleTradeType(event)}
              value={tradeType}
              title={"Select Trade Type"}
            />
            {/* <Dropdown
              val={drpValue3}
              handleSelect={(event) => handleTradingType(event)}
              value={tradingType}
              title={"Select Trading Type"}
            /> */}

            <Box className="selectionDiv">
              <Tooltip title={refresh} arrow placement="top">
                <Button
                  onClick={handleRefresh}
                  className="fetchReport-btn solidButton"
                >
                  <CloudSyncIcon />
                </Button>
              </Tooltip>
              <Tooltip title={download} arrow>
                <Button
                  onClick={handleDownload}
                  className="download-btn solidButton"
                  sx={{ marginLeft: 1.4 }}
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 11.6669V15.2224C17 15.6939 16.8127 16.1461 16.4793 16.4795C16.1459 16.8129 15.6937 17.0002 15.2222 17.0002H2.77778C2.30628 17.0002 1.8541 16.8129 1.5207 16.4795C1.1873 16.1461 1 15.6939 1 15.2224V11.6669"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4.55542 7.22229L8.99987 11.6667L13.4443 7.22229"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9 11.6667V1"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Button>
              </Tooltip>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Table col={col} rows={filteredData} small={small} />
    </Box>
  );
}
