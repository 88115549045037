import React from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import Dashboard from "./Dashboard/Dashboard";
import Partnerreport from "./Partnerreport/Partnerreport";
import Masterupdate from "./Masterupdate/Masterupdate";
import Marketing from "./Marketing/Marketing";
import Profilesetting from "./Profilesetting/Profilesetting";
import Others from "./Others/Others";
import Strategybuilder from "../Strategybuilder/Strategybuilder";

export default function Admin() {
  // admin tab
  const [value, setValue] = React.useState("admintab1");
  const handleChange = (e, newValue = "string") => {
    setValue(newValue);
  };
  return (
    <Box className="tabs">
      <TabContext value={value}>
        <TabList className="main-tab" onChange={handleChange}>
          <Tab label="Dashboard" value={"admintab1"} />
          <Tab label="Partner Report" value={"admintab2"} />
          {/* <Tab label="Master Update" value={"admintab3"} /> */}
          <Tab label="Notification" value={"admintab4"} />
          <Tab label="Vendor Profile" value={"admintab5"} />
          <Tab label="Others" value={"admintab6"} />
          {/* <Tab label="Strategy Builder" value={"admintab7"} /> */}
        </TabList>

        <Box className="tabBox" border={"none !important"}>
          <TabPanel value={"admintab1"} sx={{ padding: 0 }}>
            <Dashboard />
          </TabPanel>
          <TabPanel value={"admintab2"} sx={{ padding: 0 }}>
            <Partnerreport />
          </TabPanel>
          {/* <TabPanel value={"admintab3"} sx={{ padding: 0 }}><Masterupdate /></TabPanel> */}
          <TabPanel value={"admintab4"} sx={{ padding: 0 }}>
            <Marketing />
          </TabPanel>
          <TabPanel value={"admintab5"} sx={{ padding: 0 }}>
            <Profilesetting />
          </TabPanel>
          <TabPanel value={"admintab6"} sx={{ padding: 0 }}>
            <Others />
          </TabPanel>
          <TabPanel value={"admintab7"} sx={{ padding: 0 }}>
            <Strategybuilder />
          </TabPanel>
        </Box>
      </TabContext>
    </Box>
  );
}
