import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  Tooltip,
  TextField,
} from "@mui/material";
import Tablesearch from "../../../Tablesearch/Tablesearch";
import Table from "../../../Table/Table";
import Dropdown from "../../../Dropdown/Dropdown";
import { useDispatch, useSelector } from "react-redux";
// import { getVendorRefferal } from "../../../../redux/actions/adminActions";
import {
  editVendorReferal,
  getVendorReferal,
} from "../../../../redux/actions/brokerAction";
import CloseIcon from "@mui/icons-material/Close";
import { generatePopup } from "../../../../utils/popup";

export default function Vendorreferal() {
  const userData = useSelector((state) => state?.Auth?.authProfile?.[0]);
  const data = useSelector((state) => state?.Broker?.vendorReferalList);
  // drop down
  const drpValue = ["refercode", "phone"];

  const search = (
    <Typography component={"p"} sx={{ fontSize: "1.2rem" }}>
      Search
    </Typography>
  );

  const [searchvalue, setSearchvalue] = useState();
  const [option, setOption] = useState("");
  const [id, setId] = useState();
  const [state, setState] = useState({});
  const [type, setType] = useState("refercode");

  //input search
  const handlesearch = (event) => {
    setSearchvalue(event.target.value);
  };
  const col = [
    "Vendor Id",
    "Name",
    "Mobile",
    "Referal Code",
    "MRP",
    "M.dis",
    "Q.dis",
    "H.dis",
    "Y.dis",
    "Actions",
  ];
  const edit = <Typography sx={{ fontSize: "1.4rem" }}>Edit</Typography>;
  const save = <Typography sx={{ fontSize: "1.4rem" }}>Save</Typography>;
  const cancel = <Typography sx={{ fontSize: "1.4rem" }}>Cancel</Typography>;

  const handleEditable = (e) => {
    setId(e?.id);
    setState(e);
  };

  const handleEditChange = (e) => {
    const { name, value } = e?.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleEdit = () => {
    if (Number(state?.mrp) < 1000) {
      return generatePopup("error", "mrp should be at least 1000");
    }
    // if (
    //   state?.mdisc > 80 ||
    //   state?.qdisc > 80 ||
    //   state?.hdisc > 80 ||
    //   state?.ydisc > 80
    // ) {
    //   return generatePopup("error", "Discount Should not be more than 80%");
    // }
    const obj = {
      id: state?.id,
      mdisc: state?.mdisc,
      qdisc: state?.qdisc,
      hdisc: state?.hdisc,
      ydisc: state?.ydisc,
      mrp: state?.mrp,
    };
    dispatch(editVendorReferal(obj)).then((res) => {
      if (res) {
        setState({});
        setId("");
        if (res?.status === 200) {
          dispatch(
            getVendorReferal({
              search: "refercode",
              option: !option || option === "" ? userData?.refrecode : option,
            })
          );
        }
      }
    });
  };

  const rows = data?.map((e) => {
    return {
      VendorId: e?.id,
      Name: e?.firstname + " " + e?.lastname,
      Mobile: e?.phone,
      refercode: e?.refercode,
      mrp:
        id === e?.id ? (
          <TextField
            size="small"
            name="mrp"
            value={state?.mrp}
            sx={{
              border: "1px solid gray",
              borderRadius: "5px",
            }}
            inputProps={{ style: { fontSize: "small" } }}
            onChange={handleEditChange}
          ></TextField>
        ) : (
          e?.mrp
        ),
      mdis:
        id === e?.id ? (
          <TextField
            size="small"
            name="mdisc"
            value={state?.mdisc}
            sx={{
              border: "1px solid gray",
              borderRadius: "5px",
            }}
            inputProps={{ style: { fontSize: "small" } }}
            onChange={handleEditChange}
          ></TextField>
        ) : (
          e?.mdisc
        ),
      qdis:
        id === e?.id ? (
          <TextField
            size="small"
            name="qdisc"
            value={state?.qdisc}
            sx={{
              border: "1px solid gray",
              borderRadius: "5px",
            }}
            inputProps={{ style: { fontSize: "small" } }}
            onChange={handleEditChange}
          ></TextField>
        ) : (
          e?.qdisc
        ),
      hdis:
        id === e?.id ? (
          <TextField
            size="small"
            name="hdisc"
            value={state?.hdisc}
            sx={{
              border: "1px solid gray",
              borderRadius: "5px",
            }}
            inputProps={{ style: { fontSize: "small" } }}
            onChange={handleEditChange}
          ></TextField>
        ) : (
          e?.hdisc
        ),
      ydis:
        id === e?.id ? (
          <TextField
            size="small"
            name="ydisc"
            value={state?.ydisc}
            sx={{
              border: "1px solid gray",
              borderRadius: "5px",
            }}
            inputProps={{ style: { fontSize: "small" } }}
            onChange={handleEditChange}
          ></TextField>
        ) : (
          e?.ydisc
        ),
      Actions: (
        <Box className="tableActions">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            className="actionButton"
          >
            {e?.id !== id && (
              <Tooltip title={edit} arrow placement="top">
                <Button
                  onClick={() => handleEditable(e)}
                  className="edit"
                  variant="text"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.26 3.59997L5.04997 12.29C4.73997 12.62 4.43997 13.27 4.37997 13.72L4.00997 16.96C3.87997 18.13 4.71997 18.93 5.87997 18.73L9.09997 18.18C9.54997 18.1 10.18 17.77 10.49 17.43L18.7 8.73997C20.12 7.23997 20.76 5.52997 18.55 3.43997C16.35 1.36997 14.68 2.09997 13.26 3.59997Z"
                      stroke="CurrentColor"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.89 5.05005C12.32 7.81005 14.56 9.92005 17.34 10.2"
                      stroke="CurrentColor"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3 22H21"
                      stroke="CurrentColor"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Button>
              </Tooltip>
            )}
            {e?.id === id && (
              <>
                <Tooltip title={save} arrow placement="top">
                  <Button onClick={handleEdit} className="save" variant="text">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.8199 2H7.17995C5.04995 2 3.31995 3.74 3.31995 5.86V19.95C3.31995 21.75 4.60995 22.51 6.18995 21.64L11.0699 18.93C11.5899 18.64 12.4299 18.64 12.9399 18.93L17.8199 21.64C19.3999 22.52 20.6899 21.76 20.6899 19.95V5.86C20.6799 3.74 18.9499 2 16.8199 2Z"
                        stroke="CurrentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Button>
                </Tooltip>
                <Tooltip title={cancel} arrow placement="top">
                  <Button
                    onClick={() => {
                      setId("");
                    }}
                    className="save"
                    variant="text"
                  >
                    <CloseIcon />
                  </Button>
                </Tooltip>
              </>
            )}
          </Box>
        </Box>
      ),
    };
  });

  //select CustomerId
  const handleCustomerId = (event) => {
    setType(event.target.value);
  };
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getVendorRefferal());
  // }, []);

  const handleSearch = () => {
    dispatch(
      getVendorReferal({
        search: type,
        option: !option || option === "" ? userData?.refrecode : option,
      })
    );
  };

  useEffect(() => {
    dispatch(
      getVendorReferal({
        search: type,
        option: !option || option === "" ? userData?.refrecode : option,
      })
    );
  }, []);

  return (
    <>
      <Box
        sx={{
          display: { xs: "flex" },
          alignItems: { xs: "center" },
          justifyContent: { xs: "end" },
          marginBottom: { xs: "1.4rem" },
        }}
      ></Box>
      <Box className="tabelBox">
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={12}>
            <Box className="selectiondiv-box m-rb ">
              <Box className="selectionDiv">
                <Box
                  className="inputFields space"
                  sx={{ margin: "0 !important" }}
                >
                  <TextField
                    onChange={(e) => {
                      setOption(e?.target?.value);
                    }}
                    value={option}
                    placeholder={type?.toUpperCase()}
                    type="text"
                  />
                </Box>
              </Box>

              <Box className="selectionDiv searchFlex">
                {/* <Tablesearch handlesearch={(event) => handlesearch(event)} /> */}
                <Tooltip title={search} arrow>
                  <Button
                    onClick={handleSearch}
                    className="download-btn solidButton "
                    sx={{ marginLeft: 1 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 512 512"
                    >
                      <path
                        d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                        fill="#FFFFFF"
                      />
                    </svg>
                  </Button>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Table col={col} rows={rows} />
      </Box>
    </>
  );
}
